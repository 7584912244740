import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { bool, string } from 'prop-types'
import styled from 'styled-components'
import { colours } from '../../assets/theme'
import { useUiStore } from '../../store'

import { H2, P } from '../Text'
import { InfoButton, PositionedCross as CrossSvg, OverlayWrapper, TopLeft, ButtonRow, ResoltionSelect } from './styles'

const StyledP = styled(P)`
  &:first-of-type {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`

const renderTitles = titles =>
  titles.split('\\n').map(title => (
    <StyledP key={title} size={18}>
      {title}
    </StyledP>
  ))

const resolutions = [
  {
    value: -1,
    label: 'AUTO',
  },
  {
    value: 3,
    label: '240p',
  },
  {
    value: 0,
    label: '480p',
  },
  {
    value: 1,
    label: '360p',
  },
  {
    value: 2,
    label: '720p',
  },
  {
    value: 4,
    label: '1080p',
  }
]

const VideoOverlay = ({
  active,
  title,
  org,
  onClick,
  onClickFullscreen,
  isFullscreen,
  streamIsLive,
  videoRef
}) => {
  const setStreamActive = useUiStore(store => store.setStreamActive)
  const closeStream = () => setStreamActive(false)
  const [resoltionIndex, setResolutionIndex] = useState(0)


  useEffect(() => {
    if (videoRef) {
      videoRef.setResolution(resolutions[resoltionIndex] ? resolutions[resoltionIndex].value : -1)
    }
  }, [resoltionIndex])

  return (
    <Fragment>
      <OverlayWrapper onClick={onClick}>
        <TopLeft $active={active}>
          {/* <Logo active={active} /> */}
          {title ? renderTitles(title) : null}
        </TopLeft>
      </OverlayWrapper>
      <CrossSvg colour={colours.white} size={32} $active={active} onClick={closeStream} />
      <ButtonRow>
        <InfoButton $active={active} onClick={onClickFullscreen} postition="bl" colour={colours.midnightDarker} hoverColour={colours.offwhite}>
          {isFullscreen ? 'EXIT FULLSCREEN' : 'FULLSCREEN'}
        </InfoButton>
        {streamIsLive && resolutions ? <ResoltionSelect options={resolutions} onChange={setResolutionIndex} selectedIndex={resoltionIndex} $active={active} bottom withIcon={false} /> : null}
      </ButtonRow>
    </Fragment>
  )
}

VideoOverlay.propTypes = {
  active: bool,
  title: string.isRequired,
  org: string,
}

export default VideoOverlay
