import styled, { css } from 'styled-components'
import { colours, screenSizes } from '../../assets/theme'

const ratio169 = css`
&:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: calc((9 / 16) * 100%);
};`

export const Frame = styled.div`
  position: fixed;
  bottom: ${props => props.isMinimized ? 0 : '2em'};
  right: ${props => props.isMinimized ? 0 : '2em'};
  background-color: ${colours.white};
  padding: ${props => props.isMinimized ? '0.2em 0.2em' : '0.5em 0.5em'};
  display: flex;
  flex-direction: column;

  label {
    display: inline-block;
    margin-bottom: ${props => props.isMinimized ? 0 : '0.5em'};
    margin-right: ${props => props.isMinimized ? '0.5em' : 0};
    font-size: ${props => props.isMinimized ? '15' : '21'}px;
  }

  @media screen and (max-width: ${screenSizes.xs}px) {
    bottom: 0em;
    right: 0em;
  }
`
export const Img = styled.div`
  background: url(${({ src }) => src});
  background-size: cover;
  position: relative;
  background-position: center;
  position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `

export const Iframe = styled.iframe`
  pointer-events: none;
  position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

export const InnerWrapper = styled.div`
  cursor: pointer;
  position: relative;
  width: 16vw;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((9 / 16) * 100%);
  };

  
  
  @media screen and (max-width: ${screenSizes.xl}px) {
    width: 20vw;
  }
  
  @media screen and (max-width: ${screenSizes.lg}px) {
    width: 25vw;
  }
  
  @media screen and (max-width: ${screenSizes.md}px) {
    width: 33vw;
  }
  
  @media screen and (max-width: ${screenSizes.sm}px) {
    width: 50vw;
  }
  
  @media screen and (max-width: ${screenSizes.xs}px) {
    width: 60vw;
  }
  &:hover {
    opacity: 0.5;


    &:after {
      display: block;
      content: url("data:image/svg+xml,<svg width='32' viewbox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path stroke-width='2' stroke='white' fill='transparent' d='M2.12436,1.73205 C2.12436,0.96225 2.95769,0.481125 3.62436,0.866025 L21.6244,11.2583 C22.291,11.6432 22.291,12.6055 21.6244,12.9904 L3.62436,23.3827 C2.95769,23.7676 2.12436,23.2865 2.12436,22.5167 L2.12436,1.73205 Z'/></svg>");
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 24px;
      width: 24px;
    };
  }
`