import { Fragment, h } from 'preact'
import { string } from 'prop-types'

import { Iframe } from './styles'

const VideoEmbed = ({ url, ...rest }) => {
  const id = url.split('/').pop()
  const src = `https://tv.undersco.re/videos/embed/${id}?title=0&warningTitle=0&peertubeLink=0`
  return (
    <Fragment>
      <Iframe
        width="560"
        height="315"
        src={src}
        frameborder="0"
        allowfullscreen
        sandbox="allow-same-origin allow-scripts allow-popups"
        {...rest}
      />
    </Fragment>
  )
}

VideoEmbed.propTypes = {
  url: string,
}

export default VideoEmbed
