import styled, { css } from 'styled-components'
import { colours } from '../../assets/theme'
import Button from '../Button'
import Select from '../Select'
import Cross from '../Svg/Cross'

export const OverlayWrapper = styled.div`
  z-index: 2;
  position: fixed;
  height: 100vh;
  width: 100vw;
`
export const TopLeft = styled.div`
  opacity: 0;
  transform: translateY(-20%);
  transition: all 0.2s ease-in-out;
  padding: 2em;
  ${props =>
    props.$active &&
    css`
      transform: translateY(0%);
      opacity: 1;
    `};

  p,
  svg {
    backdrop-filter: blur(20px);
    background-color: ${colours.midnight}40;
    padding: 4px 8px;
    display: inline-block;
    margin-right: 45%;
  }
`

export const PositionedCross = styled(Cross)`
  position: fixed;
  right: 32px;
  top: 32px;
  opacity: 0.001;
  transform: translateY(-20%);
  transition: all 0.2s ease-in-out;
  transition-delay: 0.2s;
  z-index: 100;

  ${props =>
    props.$active &&
    css`
      transform: translateY(0%);
      opacity: 1;
    `};

  &:hover {
    opacity: 0.7;
  }
`

export const InfoButton = styled(Button)`
  opacity: 0.001;
  transform: translateY(
    ${props => (props.postition === 'bl' ? '20%' : '-20%')}
  );
  transition: all 0.2s ease-in-out;
  transition-delay: 0.2s;
  z-index: 100;
  width: auto;
  background-color: #ffffffba;
  padding: 0.1em 0.5em;
  font-size: 21px;
  
  ${props =>
    props.$active &&
    css`
    transform: translateY(0%);
    opacity: 1;
    `};
    
    &:hover {
      opacity: 0.7;
    }
    `

export const ButtonRow = styled.div`
  position: fixed;
  bottom: 0;
  left: 32px;
  display: flex;
  flex-direction: row;
  z-index: 100;
  
  button:not(:first-of-type) {
    margin-left: -1px;
  }

  label, button {
    font-size: 16px;
  }
  
  @media screen and (max-width: 1000px) {
    left: 0;
  }
  `

export const ResoltionSelect = styled(Select)`
  z-index: 100;
  background-color: #ffffffba;
  font-size: 21px;
  position: relative;
  bottom: -1px;

  opacity: 0.001;
  transform: translateY(20%);
  transition: all 0.2s ease-in-out;
  transition-delay: 0.2s;
  cursor: pointer;

  label {
    cursor: pointer;
  }

${props =>
    props.$active &&
    css`
    transform: translateY(0%);
    opacity: 1;
    `};
  `