export default {
  meta: {
    title: 'Underscore streams',
    description:
      'Quality live programming from beyond the surveillance economy.',
    img: 'https://stream.undersco.re/static/meta.png',
  },
  peertube_root: 'https://tv.undersco.re',
  EVENTS_API_URL: 'https://api.undersco.re',

  calendarId: 'Nt767W2KeKFSTZ8x',
  chat: {
    guildId: '709318870909059082',
    channelId: '826751398757793842',
  },
}
