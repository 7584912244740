import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { useWindowDimensions } from '../../hooks/dom'
import { useToggle } from '../../hooks/utility'

import { Label } from '../Text'
import {
  ChatFrame,
  ChatWrapper,
  ChatHeader,
  CloseBox,
  OpenIcon,
} from './styles'
import { colours } from '../../assets/theme'
import config from '../../data/config'

const Chat = ({ overlayActive }) => {
  const { width, height } = useWindowDimensions()
  const [chatIsOpen, toggleChatOpen] = useToggle(true)
  return chatIsOpen ? (
    <ChatWrapper chatIsOpen={chatIsOpen} overlayActive={overlayActive}>
      <ChatFrame>
        <ChatHeader chatIsOpen>
          <Label weight="400" size={24}>
            Chat
          </Label>
          <CloseBox colour={colours.white} size={18} onClick={toggleChatOpen} />
        </ChatHeader>
        <iframe
          src={`https://e.widgetbot.io/channels/${config.chat.guildId}/${config.chat.channelId}`}
          height={(height / 4) * 3}
          width="350"
          frameBorder="0"
          className="titanembed"
          title="discord-chat"
        />
      </ChatFrame>
    </ChatWrapper>
  ) : (
    <ChatHeader
      chatIsOpen={false}
      onClick={toggleChatOpen}
      $active={overlayActive}
    >
      <Label weight="400" size={16} colour={colours.midnightDarker}>
        CHAT
      </Label>
      <OpenIcon colour={colours.white} size={16} />
    </ChatHeader>
  )
}

export default Chat
