/* eslint-disable consistent-return */
import { useEffect, useRef } from 'preact/hooks'

export const useInterval = (callback, interval) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (interval !== null) {
      const id = setInterval(tick, interval)
      return () => clearInterval(id)
    }
  }, [interval])
}

export const useTimeout = (callback, timeout) => {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (timeout !== null) {
      const id = setTimeout(tick, timeout)
      return () => clearTimeout(id)
    }
  }, [timeout])
}
