import styled from 'styled-components'
import { screenSizes } from '../../assets/theme'
import { Row } from '../../components/Flex'

export const SeriesGrid = styled.div`
  margin-left: 32px;
`
export const ScheduleList = styled.ul`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const Day = styled.div`
  margin: 0 0 6em 0;

  h1 {
    margin-bottom: 0.5em;
  }
`

export const Content = styled.div`
  width: 85vw;
  max-width: ${screenSizes.lg}px;
  margin: 0 auto;
  padding: 64px 0;
  overflow-y: scroll;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > label {
    line-height: 1;
    max-width: 35%;
    text-align: center;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;
  `
/* ${mediaQuery.lessThan('lg')`
    max-height: calc(100vh - 200px);
  `}

  ${mediaQuery.lessThan('md')`
    max-width: 600px;
    padding: 8px 0;
    margin: 0 32px;
    `}; */