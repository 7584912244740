import { h } from 'preact'
import { bool } from 'prop-types'
import { Link } from 'react-router-dom'

import { H1 } from '../../components/Text'
import {
  Wrapper,
  Content,
  Hero,
  FadeTop,
  PositionedLink,
} from './styles'

import Header from '../../components/Header'
import { ImageLogo } from '../../components/Logo'
import { useWindowSize } from '../../hooks/dom'
import { screenSizes } from '../../assets/theme'

const InfoLayout = ({ title, subtitle, image, children, theme }) => {
  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth < screenSizes.md

  return (
    <Wrapper theme={theme}>
      <PositionedLink to="/" $theme={theme}>
        <ImageLogo />
      </PositionedLink>
      {isMobile ? <Header theme={theme} /> : null}
      <Content>
        {children}
      </Content>
      <Hero image={image}>
        <Header theme={theme} miniHeader />
        <H1 colour={theme.foreground}>{title}</H1>
        <H1 colour={theme.foreground}
          css={`
            max-width: 50%;
          `}
        >
          {subtitle}
        </H1>
        <FadeTop colour={theme.foreground} />
      </Hero>
    </Wrapper>
  )
}

export default InfoLayout
