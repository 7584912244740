import styled from 'styled-components'
import { colours, screenSizes } from '../../assets/theme'
import { H1 } from '../../components/Text'
import Link from '../../components/Link'

export const Wrapper = styled.div`
    height: calc(100vh - 100px);
  width: 100vw;
  padding: 2em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: ${colours.midnightDarker};
  box-sizing: border-box;


  @media screen and (max-width: ${screenSizes.lg}px) {
    padding: 1.5em;
  }
  @media screen and (max-width: ${screenSizes.sm}px) {
    padding: 1em;
  }
`

export const Title = styled(H1)`
  margin-bottom: 0.2em
`

export const StyledLink = styled(Link)`
  margin-top: 1em;
`

