import styled, { css } from 'styled-components'
import { colours, ui } from '../../assets/theme'
import CrossSvg from '../Svg/Cross'
import ChevronSvg from '../Svg/Chevron'
import { Label } from '../Text'

export const ChatFrame = styled.div`
  /* border: 2px solid ${colours.white}; */
  /* padding: 20px; */
`

export const ChatWrapper = styled.div`
  position: fixed;
  z-index: 10;
  bottom: -5px;
  right: 0;
  backdrop-filter: blur(20px);
  background-color: ${colours.midnight}60;
  border-radius: ${ui.borderRadius}px;
  opacity: ${props => (!props.chatIsOpen && !props.overlayActive ? 0 : 1)};
`

export const ChatHeader = styled.div`
  position: absolute;
  bottom: ${props => (props.chatIsOpen ? 'initial' : '0')};
  top: ${props => (props.chatIsOpen ? '0px' : 'initial')};
  border-radius: ${props =>
    props.chatIsOpen ? `${ui.borderRadius}px 0 0 0` : '0'};
  z-index: 2;
  background-color: ${props => (props.chatIsOpen ? `${colours.midnightDarker}db` : '#ffffffba')};
  backdrop-filter: blur(2px);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: ${props => (props.chatIsOpen ? '100%' : 'fit-content')};

  justify-content: space-between;
  padding: ${props => props.chatIsOpen ? '0px 0px 3px 0px' : '0.3em 0'};
  right: ${props => (props.chatIsOpen ? '0' : '32px')};
  box-sizing: content-box;
  border: ${props =>
    props.chatIsOpen ? 'none' : `1px solid ${colours.midnightDarker}`};
  border-bottom: ${props =>
    props.chatIsOpen ? `1px solid ${colours.white}75` : 'none'};
  /* border-right: none; */

  label {
    margin-left: 12px;
    margin-right: ${props => (props.chatIsOpen ? '0' : '12px')};
    color: ${props =>
    props.chatIsOpen ? colours.white : colours.midnightDarker};
  }
  svg path {
    fill: ${props =>
    props.chatIsOpen ? colours.white : colours.midnightDarker};
  }

  opacity: 0.001;
  transform: translateY(20%);
  transition: all 0.2s ease-in-out;
  transition-delay: 0.2s;
  cursor: pointer;

${props =>
    (props.$active || props.chatIsOpen) &&
    css`
      transform: translateY(0%);
      opacity: 1;
    `};
`

export const CloseBox = styled(CrossSvg)`
  padding: 12px;
  cursor: pointer;
`

export const OpenIcon = styled(ChevronSvg)`
  padding-right: 12px;
  cursor: pointer;
`
