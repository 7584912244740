import styled from 'styled-components'
import { colours, screenSizes } from '../../assets/theme'
import bg from '../../assets/img/hero/1lg.png'

// import { H1 } from '../../components/Text'

import { ImageLogo as Logo } from '../../components/Logo'

const heroWidth = 'calc(100vw - 600px - 4em)'

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  /* padding: 2em; */
  display: flex;
  background-color: ${colours.midnightDarker};
  box-sizing: border-box;
  position: fixed;
  overflow-y: scroll;
`

export const ErrorBlock = styled.div`
  padding: 1em;

  h1 {
    margin-bottom: 0.5em
  }
`

export const Top = styled.div`
  width: 50%;
`

const gradientColourLight = '#F8E5E2'
const gradientColourDark = colours.midnightDarker
const getGradient = (direction, lightDark) =>
  `linear-gradient(to ${direction}, ${lightDark === 'dark' ? gradientColourDark : gradientColourLight
  }ee 0%,${lightDark === 'dark' ? gradientColourDark : gradientColourLight
  }00 100%);`

// prettier-ignore
export const Fade = styled.div`
  width: 100%;
  background-color: linear;
  position: fixed;
  padding: 2em 0 1em 2em;
  top: 0;
  left: 0;
  background: ${getGradient('bottom')};
`

export const Hero = styled.div`
  width: ${heroWidth};
  height: 100vh;
  background: url(${bg});
  background-size: cover;
  background-position-x: right;
  background-position-y: 60%;
  position: fixed;
  padding: 0;
  right: 0;
  top: 0;
  padding: 2em 2em 8px 2em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;

  @media screen and (max-width: ${screenSizes.md}px) {
    display: none;
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  width: calc(600px + 4em);

  @media screen and (max-width: ${screenSizes.md}px) {
    width: 100vw;
  }
`

export const Content = styled.div`
  /* margin-bottom: 3em; */
`

export const PositionedLogo = styled(Logo)`
  /* position: fixed; */
  max-height: 80px;
  mix-blend-mode: exclusion;
  padding: 1em;
`

export const FadeBottom = styled.div`
  background: ${getGradient('top', 'dark')};
  width: ${heroWidth};
  position: fixed;
  bottom: 0;
  padding-bottom: 0.5em;
  right: 0;
  /* left: 0; */
  pointer-events: none;
  min-height: 75px;
`

export const TaglineContainer = styled.div`
  width: ${heroWidth};
  bottom: 0em;
  padding-bottom: 0;
  right: 1em;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  
  position: fixed;
  z-index: 2;
  
  h1 {
    margin-bottom: 0.2em;
    text-align: right;
  }
  
  @media screen and (max-width: ${screenSizes.md}px) {
    width: 100%;
    h1 {
      color: ${colours.rose};
      font-size: 24px;
    }
  }
`
