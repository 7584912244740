import { formatDistanceToNow } from 'date-fns'
import { h } from 'preact'
import Link from '../Link'
import { H2, H3, Label } from '../Text'
import strings from '../../data/strings'
import { Img, LabelBlock, Wrapper } from './styles'
import { andList } from '../../helpers/string'
import { colours } from '../../assets/theme'

const SeriesCard = ({
  series: { image, episodes, title, subtitle, hosts: hostsArray, slug },
  isPast,
  ...rest
}) => {
  const hosts = hostsArray.map(({ actor }) => actor.name)

  const episodesLength = episodes.past.length + episodes.future.length

  const getNextLastStreamText = () => {
    const hasFutureEpisodes = episodes.future.length
    const prefix = hasFutureEpisodes
      ? strings.en.nextStream
      : strings.en.lastStream

    const episodesList = episodes[hasFutureEpisodes ? 'future' : 'past']

    const mainText = formatDistanceToNow(
      new Date(
        episodesList[hasFutureEpisodes ? 0 : episodesList.length - 1][
          hasFutureEpisodes ? 'endsOn' : 'beginsOn'
        ]
      ),
      { addSuffix: true }
    )

    return `${prefix} ${mainText}`
  }

  return (
    <Link to={`series/${slug}`}>
      <Wrapper {...rest}>
        <Img src={image}>
          <LabelBlock $position="top">
            {episodesLength}{' '}
            {episodesLength === 1 ? strings.en.episode : strings.en.episodes}
          </LabelBlock>
          <LabelBlock $position="bottom">{getNextLastStreamText()}</LabelBlock>
        </Img>

        <H2 size={32} colour={colours.rose}>
          {title}
        </H2>
        <H3 size={21} colour={colours.rose}>
          {subtitle}
        </H3>
        {hosts.length ? (
          <Label size={16} colour={colours.rose}>
            — {andList(hosts)}
          </Label>
        ) : null}
      </Wrapper>
    </Link>
  )
}

export default SeriesCard
