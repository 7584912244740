"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeerTubePlayer = void 0;
var Channel = require("jschannel");
var events_1 = require("./events");
var PASSTHROUGH_EVENTS = [
    'pause',
    'play',
    'playbackStatusUpdate',
    'playbackStatusChange',
    'resolutionUpdate',
    'volumeChange'
];
var PeerTubePlayer = (function () {
    function PeerTubePlayer(embedElement, scope) {
        this.embedElement = embedElement;
        this.scope = scope;
        this.eventRegistrar = new events_1.EventRegistrar();
        this.eventRegistrar.registerTypes(PASSTHROUGH_EVENTS);
        this.constructChannel();
        this.prepareToBeReady();
    }
    PeerTubePlayer.prototype.destroy = function () {
        this.embedElement.remove();
    };
    PeerTubePlayer.prototype.addEventListener = function (event, handler) {
        return this.eventRegistrar.addListener(event, handler);
    };
    PeerTubePlayer.prototype.removeEventListener = function (event, handler) {
        return this.eventRegistrar.removeListener(event, handler);
    };
    Object.defineProperty(PeerTubePlayer.prototype, "ready", {
        get: function () {
            return this.readyPromise;
        },
        enumerable: false,
        configurable: true
    });
    PeerTubePlayer.prototype.play = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sendMessage('play')];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PeerTubePlayer.prototype.pause = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sendMessage('pause')];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PeerTubePlayer.prototype.setVolume = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sendMessage('setVolume', value)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PeerTubePlayer.prototype.getVolume = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.sendMessage('getVolume')];
            });
        });
    };
    PeerTubePlayer.prototype.setCaption = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sendMessage('setCaption', value)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PeerTubePlayer.prototype.getCaptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.sendMessage('getCaptions')];
            });
        });
    };
    PeerTubePlayer.prototype.seek = function (seconds) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sendMessage('seek', seconds)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PeerTubePlayer.prototype.setResolution = function (resolutionId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sendMessage('setResolution', resolutionId)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PeerTubePlayer.prototype.getResolutions = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.sendMessage('getResolutions')];
            });
        });
    };
    PeerTubePlayer.prototype.getPlaybackRates = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.sendMessage('getPlaybackRates')];
            });
        });
    };
    PeerTubePlayer.prototype.getPlaybackRate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.sendMessage('getPlaybackRate')];
            });
        });
    };
    PeerTubePlayer.prototype.setPlaybackRate = function (rate) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sendMessage('setPlaybackRate', rate)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PeerTubePlayer.prototype.playNextVideo = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sendMessage('playNextVideo')];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PeerTubePlayer.prototype.playPreviousVideo = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.sendMessage('playPreviousVideo')];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PeerTubePlayer.prototype.getCurrentPosition = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.sendMessage('getCurrentPosition')];
            });
        });
    };
    PeerTubePlayer.prototype.constructChannel = function () {
        this.channel = Channel.build({
            window: this.embedElement.contentWindow,
            origin: '*',
            scope: this.scope || 'peertube'
        });
        this.eventRegistrar.bindToChannel(this.channel);
    };
    PeerTubePlayer.prototype.prepareToBeReady = function () {
        var readyResolve;
        var readyReject;
        this.readyPromise = new Promise(function (res, rej) {
            readyResolve = res;
            readyReject = rej;
        });
        this.channel.bind('ready', function (success) { return success ? readyResolve() : readyReject(); });
        this.channel.call({
            method: 'isReady',
            success: function (isReady) { return isReady ? readyResolve() : null; }
        });
    };
    PeerTubePlayer.prototype.sendMessage = function (method, params) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.channel.call({
                method: method,
                params: params,
                success: function (result) { return resolve(result); },
                error: function (error) { return reject(error); }
            });
        });
    };
    return PeerTubePlayer;
}());
exports.PeerTubePlayer = PeerTubePlayer;
window['PeerTubePlayer'] = PeerTubePlayer;
