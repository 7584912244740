"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventRegistrar = void 0;
var EventRegistrar = (function () {
    function EventRegistrar() {
        this.eventRegistrations = {};
    }
    EventRegistrar.prototype.bindToChannel = function (channel) {
        var _this = this;
        var _loop_1 = function (name_1) {
            channel.bind(name_1, function (txn, params) { return _this.fire(name_1, params); });
        };
        for (var _i = 0, _a = Object.keys(this.eventRegistrations); _i < _a.length; _i++) {
            var name_1 = _a[_i];
            _loop_1(name_1);
        }
    };
    EventRegistrar.prototype.registerTypes = function (names) {
        for (var _i = 0, names_1 = names; _i < names_1.length; _i++) {
            var name_2 = names_1[_i];
            this.eventRegistrations[name_2] = { registrations: [] };
        }
    };
    EventRegistrar.prototype.fire = function (name, event) {
        this.eventRegistrations[name].registrations.forEach(function (x) { return x(event); });
    };
    EventRegistrar.prototype.addListener = function (name, handler) {
        if (!this.eventRegistrations[name]) {
            console.warn("PeerTube: addEventListener(): The event '" + name + "' is not supported");
            return false;
        }
        this.eventRegistrations[name].registrations.push(handler);
        return true;
    };
    EventRegistrar.prototype.removeListener = function (name, handler) {
        if (!this.eventRegistrations[name])
            return false;
        this.eventRegistrations[name].registrations = this.eventRegistrations[name].registrations.filter(function (x) { return x === handler; });
        return true;
    };
    return EventRegistrar;
}());
exports.EventRegistrar = EventRegistrar;
