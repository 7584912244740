import styled from 'styled-components'
import { colours, screenSizes } from '../../assets/theme'
import { Label, H2 } from '../Text'
import Flexbox, { Row as FlexRow, Column as FlexColumn } from '../Flex'
import Button from '../Button'

const imageWidthStyles = `
width: 25vw;
  
@media screen and (max-width: ${screenSizes.md}px) {
  width: 85vw;
}
`

export const ButtonRow = styled(Flexbox)`
  width: 100%;
  align-items: stretch;

  button,
  a {
    font-size: 16px;
    width: 49%;
    height: 100%;
  }

  a button {
    width: 100%;
  }

  @media screen and (min-width: ${screenSizes.md}px) and (max-width: ${screenSizes.lg}px) {
    flex-direction: column;

    button,
    a {
      width: 100%;
      height: auto;
    }
  }
`

export const Left = styled(FlexColumn)`
  margin-right: 2em;
  ${imageWidthStyles};

  @media screen and (max-width: ${screenSizes.md}px) {
    margin-right: 0em;
  }
`

export const Center = styled(FlexColumn)`
  @media screen and (max-width: ${screenSizes.md}px) {
    order: 2;
    position: relative;
    top: -1em;
  }
`

export const Title = styled(H2)`
  max-width: 80%;
  margin-bottom: 1em;
`

export const Right = styled.div`
  flex: 1;
  text-align: right;

  label {
    display: block;
    margin-bottom: 0.2em;
  }

  @media screen and (max-width: ${screenSizes.md}px) {
    position: relative;
    top: 1.2em;
    order: 1;
  }
`
export const StyledButton = styled(Button)`
  margin-top: 0.5em;
  padding: 0.3em 2em;

  @media screen and (max-width: ${screenSizes.md}px) {
    margin: 0.5em 0;
  }
`

export const Img = styled.div`
  background: url(${({ src }) => src});
  padding-bottom: calc((9 / 16) * 100%);
  background-size: cover;
  position: relative;
  background-position: center;
  ${imageWidthStyles};
`
