import { h } from 'preact'
import { useRef, useState } from 'preact/hooks'
import { useInterval, useTimeout } from '../../hooks/timerHooks'
import { colours } from '../../assets/theme'
import { H1 } from '../Text'

const defaultLoader = [':..', '.:.', '..:', '...']
// const symbols = ['⌏', '⌎', '⌌', '⌍']

const Loader = ({
  active = true,
  offset = 0,
  animation = defaultLoader,
  colour = colours.rose,
  rate = 300
}) => {
  const [text, setText] = useState('.')
  const arrayPosition = useRef(offset)

  useInterval(
    () => {
      setText(animation[arrayPosition.current])

      if (arrayPosition.current === animation.length - 1) {
        arrayPosition.current = 0
      } else {
        arrayPosition.current += 1
      }
    },
    active ? rate : null
  )

  return (
    <H1 as="span" colour={colour}>
      {text}
    </H1>
  )
}

export default Loader
