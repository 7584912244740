import { Fragment, h } from 'preact'
import PropTypes, { oneOfType, shape, string } from 'prop-types'
import { useEffect } from 'preact/hooks'

import SEO from '../../components/Seo'

import Header, { NavigationModal as MenuModal } from '../../components/Header'
import { capitaliseFirstLetter } from '../../helpers/string'
import { defaultTheme } from '../../assets/theme'
import { ThemedBlock } from './styles'
import { useStreamStore, useTheme, useUiStore } from '../../store'
import StreamPreview from '../../components/StreamPreview'

const Page = ({ children, title = '', description, metaImg, backTo, noindex, withHeader = true, theme = defaultTheme }) => {
  const { setTheme } = useTheme(store => store)
  const { mobileMenuOpen: menuOpen, toggleMenuOpen } = useUiStore(store => store)


  useEffect(() => {
    if (theme) {
      setTheme(theme)
    }
  }, [])

  return (
    <Fragment>
      <SEO
        title={title.toLowerCase() === 'index' ? title : capitaliseFirstLetter(title)}
        description={description}
        metaImg={metaImg}
        noindex={noindex}
      />
      {withHeader ? <Header theme={theme} /> : null}
      {menuOpen ? <MenuModal theme={theme} toggleMenuOpen={toggleMenuOpen} /> : null}
      <ThemedBlock theme={theme} withHeader={withHeader}>
        {children}
      </ThemedBlock>
    </Fragment>
  )
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: oneOfType([
    string,
    shape({
      en: string,
      fr: string,
    }),
  ]),
  description: string,
  metaImg: string,
}

export default Page
