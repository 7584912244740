import { h } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import { PeerTubePlayer } from '@peertube/embed-api'
import { Label } from '../Text'
import { colours, textSizes } from '../../assets/theme'
import { Row } from '../Flex'
import CrossSvg from '../Svg/Cross'
import { useUiStore } from '../../store'
import { getLabel } from './helpers'
import Chevron from '../Svg/Chevron'

import { Frame, Img, Iframe, InnerWrapper } from './styles'

const StreamPreview = ({ stream, isLive, ...rest }) => {
  const currentLanguage = 'en'
  const videoiFrame = useRef(null)
  const ptVideo = useRef(null)
  const { isMinimized, toggleMinimized, setStreamActive } = useUiStore(
    store => ({
      isMinimized: store.streamPreviewMinimized,
      toggleMinimized: store.toggleStreamPreviewMinimized,
      setStreamActive: store.setStreamActive,
    })
  )

  useEffect(() => {
    const setupAndPlayVideo = async () => {
      const player = new PeerTubePlayer(videoiFrame.current)
      await player.ready

      ptVideo.current = player
      player.setVolume(0)
      player.play()
    }
    if (isLive) {
      setupAndPlayVideo()
    }
  }, [isLive, isMinimized])

  const activateStream = () => {
    setStreamActive(true)
  }

  return stream ? (
    <Frame isMinimized={isMinimized}>
      <Row justify="space-between">
        <Label
          colour={colours.midnightDarker}
          size={textSizes.lg}
          onClick={activateStream}
        >
          {getLabel(stream, isLive, isMinimized)}
        </Label>
        {isMinimized ? (
          <Chevron
            colour={colours.midnightDarker}
            size={14}
            onClick={toggleMinimized}
          />
        ) : (
          <CrossSvg
            colour={colours.midnightDarker}
            size={16}
            onClick={toggleMinimized}
          />
        )}
      </Row>
      {!isMinimized ? (
        <InnerWrapper onClick={activateStream}>
          {isLive ? (
            <Iframe
              width="560"
              height="315"
              sandbox="allow-same-origin allow-scripts allow-popups"
              autoplay="autoplay"
              muted="muted"
              src={`https://tv.undersco.re${stream.embedPath}?api=1&controls=false`}
              frameBorder="0"
              allowFullScreen
              ref={videoiFrame}
            />
          ) : (
            <Img src={stream.image} onClick={activateStream} />
          )}
        </InnerWrapper>
      ) : null}
    </Frame>
  ) : null
}

export default StreamPreview
