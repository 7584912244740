import { h } from 'preact'

import translations from '../../data/strings'
import { H2 } from '../../components/Text'
import {
  Wrapper,
  Title,
  StyledLink as Link
} from './styles'
import { textSizes } from '../../assets/theme'
import Page from '../../layouts/Page'

const FourOhFour = () => (
  <Page title={translations.en.pageNotFound} noindex>
    <Wrapper>
      <Title size={textSizes.hg}>{translations.en.fourohfour}</Title>
      <H2>{translations.en.pageNotFound}</H2>
      <Link to="/">{translations.en.goHome}</Link>
    </Wrapper>
  </Page>
)

export default FourOhFour
