import styled from 'styled-components'
// import { defaultTheme } from '../../assets/theme'

export const ThemedBlock = styled.div`
  color: ${({ theme }) => theme.foreground};
  background-color: ${({ theme }) => theme.background};
  padding-top: ${({ withHeader }) => withHeader ? '100px' : 0};
  min-height: 100vh;
  height: 100%;
  min-width: 100vw;
  box-sizing: border-box;
`