import styled, { css } from 'styled-components'
import { colours } from '../../assets/theme'

export const TextBase = styled.span`
  ${({
  $size,
  $fontStyle,
  weight,
  colour,
  align,
  lineHeight,
  opacity = 1,
  $fontFamily: fontFamily,
  selectable,
  underline,
  $sizeUnit,
}) => css`
    font-family: ${fontFamily};
    font-weight: ${weight};
    text-align: ${align};
    color: ${colour};
    line-height: ${lineHeight};
    opacity: ${opacity};
    user-select: ${selectable ? 'inherit' : 'none'};
    text-decoration: ${underline ? 'underline' : 'none'};
    font-size: ${`${$size}${$sizeUnit || 'px'}`};
    font-style: ${$fontStyle};

    ::selection {
      background-color: ${colours.midnightDarker};
      color: ${colours.offwhite};
    }
  `}
`
