import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colours } from '../../assets/theme'

export const RRLink = styled(Link)`
  color: ${props => props.$colour || colours.highlight};
  text-decoration: none;
  ${props => props.$navLink ? `
    font-family: Lunchtype24;
  ` : ''};
  position: relative;
  
  ${props => props.$selected ? `
  &::before, &::after {
    color: ${colours.highlight};
      font-size: 21px;
      position: absolute;
      top: 45%;
      font-family: 'Karla';
      transform: translateY(-50%);
    }
    
    &::before {
      content: '< ';  
      left: -1em;
    }
    &::after {
      content: ' >';
      right: -1em;
    }
  ` : ''};
`