import { h } from 'preact'
import { Link as ReactLink } from 'react-router-dom'
import { RightBox, StyledRow as Row, Modal, PositionedCross as CrossSvg } from './styles'
import { ImageLogo } from '../Logo'
import { useWindowSize } from '../../hooks/dom'
import Link from '../Link'
import { Span } from '../Text'

import navigation from '../../data/navigation'
import { colours, screenSizes, textSizes } from '../../assets/theme'
import { useTheme, useUiStore } from '../../store'
import strings from '../../data/strings'

const Navigation = ({ theme = {}, lang = 'en', miniHeader, toggleMobileMenu }) => navigation[lang].map(navItem => (
  <Link
    navLink
    to={navItem.to}
    href={navItem.href}
    onClick={toggleMobileMenu}
    textProps={{
      size: textSizes.xl,
      colour: miniHeader ? theme.background : theme.foreground || colours.rose
    }}>
    {navItem.label}
  </Link>
))

export const NavigationModal = ({ theme = {}, lang = 'en', headerTheme, ...rest }) => {
  const { toggleMobileMenu } = useUiStore(store => store)

  return (
    <Modal {...rest}>
      <ReactLink to="/">
        <ImageLogo />
      </ReactLink>
      <CrossSvg size={32} colour={theme.foreground} onClick={toggleMobileMenu} />
      <div>
        <Navigation theme={theme} lang={lang} headerTheme={theme} toggleMobileMenu={toggleMobileMenu} {...rest} />
      </div>
    </Modal>
  )
}

const FullHeader = ({ theme = {}, lang = 'en', miniHeader, isMobile, ...rest }) => (
  <Row align="center" justify="space-between" miniHeader={miniHeader} {...rest}>
    {!miniHeader ? <ReactLink to="/">
      <ImageLogo />
    </ReactLink> : null}
    {!isMobile ? (
      <RightBox as="nav">
        <Navigation theme={theme} lang={lang} miniHeader={miniHeader} {...rest} />
      </RightBox>
    ) : <MobileMenuToggle miniHeader={miniHeader} />}
  </Row>
)

export const MobileMenuToggle = ({ miniHeader, ...props }) => {
  const { toggleMobileMenu } = useUiStore(store => store)
  const { theme } = useTheme(store => store)

  return (
    <Span
      onClick={toggleMobileMenu}
      size={textSizes.xl}
      colour={miniHeader ? theme.background : theme.foreground || colours.rose}
      fontFamily="Lunchtype24"
      {...props}
    >
      {strings.en.menu}
    </Span>)
}



const Header = ({ miniHeader, theme, ...rest }) => {
  const { width: screenWidth } = useWindowSize()
  const { menuOpen } = useUiStore(store => store)
  const isMobile = screenWidth < screenSizes.lg

  return <FullHeader menuOpen={menuOpen} miniHeader={miniHeader} isMobile={isMobile} theme={theme} {...rest} />
}

export default Header
