/* eslint-disable react/prop-types */
import { isWithinInterval } from 'date-fns'
import { h } from 'preact'
import { H1, H2, Label } from '../../components/Text'
import strings from '../../data/strings'
import { useEventApi } from '../../hooks/data'
import { Content, ScheduleList, Day } from './styles'

import Page from '../../layouts/Page'
import { formatDay, getScheduleFromData } from './helpers'
import EpisodeCard from '../../components/EpisodeCard'
import { colours, textSizes } from '../../assets/theme'

const Program = () => {
  const { data } = useEventApi()

  const episodes = getScheduleFromData(data.episodes)

  return (
    <Page title={strings.en.program}>
      <Content>
        {Object.entries(episodes).length === 0 ? (
          <Label size={textSizes.lg}>There are currently no streams scheduled, check back soon!</Label>
        ) :
          <ScheduleList>
            {Object.keys(episodes || {}).sort((a, b) => new Date(a) - new Date(b)).map(day => (
              <Day>
                <H1 colour={colours.rose}>{formatDay(day)}</H1>
                {episodes[day].map(episode => (
                  <EpisodeCard {...episode} />
                ))}

              </Day>
            ))}
          </ScheduleList>}
        {/* <H1>Program</H1> */}

      </Content>
    </Page>
  )
}

export default Program
