import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { colours, screenSizes } from '../../assets/theme'
import { ImageLogo as Logo } from '../../components/Logo'

const heroWidth = 'calc(100vw - 600px - 4em)'

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  padding: 2em;
  display: flex;
  box-sizing: border-box;
  position: fixed;
  overflow-y: scroll;
  
  
  @media screen and (max-width: ${screenSizes.lg}px) {
    padding: 1.5em;
  }
  @media screen and (max-width: ${screenSizes.md}px) {
    /* padding: 1.5em 1.5em 1.5em 10em; */
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: ${screenSizes.sm}px) 
    padding: 1em;
  }
  
  background-color: ${props => props.theme.background};
  p,
  h1,
  h2,
  h3 {
    color: ${props => props.theme.foreground};
  }
  
  button {
    color: ${props => props.theme.foreground};
    border-color: ${props => props.theme.foreground};
    
    &:hover {
      border-color: ${props => props.theme.background};
      color: ${props => props.theme.background};
      background-color: ${props => props.theme.foreground};

    }
  }
  
`

export const Top = styled.div`
  width: 50%;
`

const getGradient = (direction, colour) =>
  `linear-gradient(to ${direction}, ${colour}ee 0%,${colour}00 100%);`

export const Hero = styled.div`
  width: ${heroWidth};
  height: 100vh;
  background: url(${(props) => props.image});
  background-size: cover;
  background-position-x: center;
  position: fixed;
  padding: 0;
  right: 0;
  top: 0;
  padding: 2em 2em 8px 2em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;
  h1,
  h2 {
    color: ${colours.offwhite};
  }

  h1{
    margin-bottom: 0.2em;
    &:not(:last-of-type) {
    font-size: 12.5vw;

    @media screen and (max-width: ${screenSizes.lg}px) {
      font-size: 9vw;
    }
    @media screen and (min-width: ${screenSizes.lg}px) {
      font-size: 12vw;
    }
  }}
  
  
  @media screen and (max-width: ${screenSizes.md}px) {
    display: none;
  }
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 600px;
`

export const Content = styled.div`
  padding-top: 80px;
  
  @media screen and (max-width: ${screenSizes.md}px) {
    padding-top: 100px;
  }

  img.img-logo {
    max-height: 80px;
    mix-blend-mode: exclusion;
  }
`

export const PositionedLink = styled(Link)`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  background-color: ${({ $theme }) => $theme.background};

  &:hover img {
    filter: invert(1);
    mix-blend-mode: normal;
  }

  img {
    max-height: 80px;
    mix-blend-mode: exclusion;
    padding: 0.5em 2em 0;

    @media screen and (max-width: ${screenSizes.md}px) {
      padding-left: 1em
    }
  }
`

export const FadeTop = styled.div`
  background: ${({ colour }) => colour ? getGradient('bottom', colour) : colours.rose};
  width: ${heroWidth};
  position: fixed;
  top: 0em;
  padding-bottom: 0.5em;
  right: 0;
  pointer-events: none;
  min-height: 75px;
`

export const TaglineContainer = styled.div`
  width: 100%;
  bottom: 0em;
  padding-bottom: 0.5em;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 2;

  a {
    pointer-events: all;
  }
`
