export default {
  en: {
    program: 'Program',
    pastStream: 'Previous Episodes',
    nowPlaying: 'Currently streaming',
    startingSoon: 'Starting soon',
    streamStartingSoon: 'Stream starting soon',
    noStreams: 'No upcoming streams, check back soon.',
    underscoreTagline: ['LEAVE THE', 'SURVEILLANCE ECONOMY', '— TOGETHER.'],
    streamDateFuture: 'Going live at: ',
    streamDatePast: 'First broadcast: ',
    subEvent: 'Add to calendar',
    watchEpisode: 'Watch Episode',
    watchTrailer: 'Watch the trailer',
    joinStream: 'Click to join stream',
    fourohfour: '404',
    pageNotFound: 'Page not found',
    goHome: 'Return to Home',
    series: 'Series',
    currentSeries: 'Current Series',
    pastSeries: 'Past Series',
    lastStream: 'Last stream',
    nextStream: 'Next stream',
    episode: 'episode',
    episodes: 'episodes',
    today: 'today',
    tomorrow: 'tomorrow',
    eventDetails: 'Event details',
    errorTitle: 'Hmm...',
    errorBody: 'Something went wrong, please try again later',
    menu: 'Menu'
  },
}
