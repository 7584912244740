import { bool, number, oneOf } from 'prop-types'
import styled from 'styled-components'

const Flexbox = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction || 'row')};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'flex-start'};
  ${props => props.flex ? `
    flex: ${props.flex};
  ` : ''}
`

export const Row = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'flex-start'};
  ${props => props.flex ? `
    flex: ${props.flex};
  ` : ''}
`

export const Column = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'column-reverse' : 'column')};
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'flex-start'};
  ${props => props.flex ? `
    flex: ${props.flex};
  ` : ''}
`
const propTypes = {
  reverse: bool,
  justify: oneOf(['flex-start', 'flex-end', 'center', 'space-around', 'space-between']),
  align: oneOf(['flex-start', 'flex-end', 'center', 'stretch']),
  flex: number
}

Row.propTypes = propTypes
Column.propTypes = propTypes

export default Flexbox