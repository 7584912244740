import { Fragment, h } from 'preact'
import { arrayOf, number, shape, string } from 'prop-types'
import { useCallback, useRef, useState } from 'preact/hooks'
import { withTheme } from 'styled-components'
import { useOnClickOutside } from '../../hooks/dom'
import { Label } from '../Text'
import { Box, Item, Container, OptionsWrapper, ChevronIcon } from './styles'
import { colours } from '../../assets/theme'

const Select = withTheme(({ options, selectedIndex, onChange, theme, bottom, withIcon = true, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)
  const close = () => { setIsOpen(false) }
  const open = () => { setIsOpen(true) }
  const optionsRef = useRef([])
  const toggle = () => { setIsOpen(itsopen => !itsopen) }
  const ref = useRef()
  useOnClickOutside(ref, useCallback(() => close()))

  const handleItemClick = (index) => {
    if (typeof onChange === 'function') {
      close()
      onChange(index)
    }
  }

  const handleKeyPress = (e) => {
    e.preventDefault()
    if (e.keyCode === 32) {
      open()
    }
    if (e.keyCode === 40) {
      open()
      console.log('optionsRef.current', optionsRef.current)
      // optionsRef.current[1].focus();
    }
  }

  return (
    <Container ref={ref} {...rest}>
      {options && options.length ? (
        <Fragment>
          <Box onClick={toggle} tabIndex={0} onKeyDown={handleKeyPress} data-hoverable>
            <Label colour={colours.midnight}>{options[selectedIndex].label}</Label>
            {withIcon && <ChevronIcon colour={colours.midnight} size={14} />}
          </Box>
          {isOpen && options && options.length ? (
            <OptionsWrapper ref={optionsRef} bottom={bottom} >
              {options.map((option, optionIndex) => option.value !== options[selectedIndex].value && (
                <Item tabIndex={0} data-hoverable onClick={() => handleItemClick(optionIndex)} key={option.value} ref={optionsRef[optionIndex]}>
                  {option.label}
                </Item>
              ))}
            </OptionsWrapper>
          ) : null}
        </Fragment>
      ) : <Box />}
    </Container>

  )
})

Select.propTypes = {
  options: arrayOf(shape({
    label: string,
  })),
  selectedIndex: number,
}

export default Select
