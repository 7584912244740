import { Fragment, h, render } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import { ThemeProvider } from 'styled-components'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { isWithinInterval, subHours, addHours } from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

import Main from './app'
import SeriesPage from './src/pages/SeriesPage'
import { useEventApi, usePeertubeApi } from './src/hooks/data'
import { useStreamStore, useTheme, useUiStore } from './src/store'
import { useTimeout } from './src/hooks/timerHooks'
import LoaderLayout from './src/pages/LoaderLayout'
import FourOhFour from './src/pages/404'
import Series from './src/pages/Series'
import Program from './src/pages/Program'
import StreamPreview from './src/components/StreamPreview'
import Video from './src/components/Video'
import Chat from './src/components/Chat'
// import { useWindowSize } from './src/hooks/dom'

const App = () => {
  const { theme } = useTheme(store => store)
  const { data, loading: eventsLoading, error } = useEventApi()
  const [minLoadTimePassed, setMinTimeUp] = useState(false)
  const { setCurrentStream, currentStream, streamIsLive } = useStreamStore(
    store => store
  )
  const streamActive = useUiStore(store => store.streamActive)
  usePeertubeApi(data.episodes)

  useTimeout(() => {
    setMinTimeUp(true)
  }, 1500)

  useEffect(() => {
    if (Array.isArray(data.episodes)) {
      data.episodes.forEach(stream => {
        const utcStartDate = zonedTimeToUtc(
          new Date(stream.beginsOn),
          'Europe/Berlin'
        )
        const utcEndDate = zonedTimeToUtc(
          new Date(stream.endsOn),
          'Europe/Berlin'
        )
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

        const zonedStartDate = utcToZonedTime(utcStartDate, 'Europe/Berlin')
        const zonedEndDate = utcToZonedTime(utcEndDate, 'Europe/Berlin')
        if (
          isWithinInterval(new Date(), {
            start: subHours(zonedStartDate, 2),
            end: addHours(zonedEndDate, 1),
          })
        ) {
          setCurrentStream(stream)
        }
      })
    }
  }, [eventsLoading])

  const seriesData = data.series ? Object.values(data.series) : []

  return (
    <ThemeProvider theme={theme}>
      {!seriesData.length || eventsLoading || !minLoadTimePassed || error ? (
        <LoaderLayout error={error} />
      ) : (
        <Fragment>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Program} />
              <Route exact path="/series" component={Series} />
              <Route exact path="/program" component={Program} />
              {seriesData.length
                ? seriesData.map(series => (
                    <Route exact path={`/series/${series.slug}`}>
                      <SeriesPage data={series} />
                    </Route>
                  ))
                : null}
              <Route path="*">
                <FourOhFour />
              </Route>
            </Switch>
          </BrowserRouter>

          {streamActive ? (
            <Video stream={currentStream} />
          ) : (
            <StreamPreview stream={currentStream} isLive={streamIsLive} />
          )}
        </Fragment>
      )}
    </ThemeProvider>
  )
}

const appEl = document.getElementById('app')

render(<App />, appEl)
