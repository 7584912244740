export const colours = {
  midnight: '#233b4a',
  midnightDarker: '#112B39',
  offwhite: '#f6f4f5',

  white: '#ffffff',
  highlight: '#03a59e',
  roseDarker: '#FEB9B3',
  rose: '#F1CFCD',
  roseLight: '#F8E5E1',
}

colours.text = colours.offwhite

export const ui = {
  borderRadius: 4,
}

export const textSizes = {
  xs: 11,
  sm: 14,
  md: 16,
  lg: 21,
  xl: 28,
  xxl: 92,
  hg: 200,
}

export const screenSizes = {
  xs: 670,
  sm: 800,
  md: 1000,
  lg: 1500,
  xl: 1700,
}

export const defaultTheme = {
  background: colours.midnightDarker, foreground: colours.rose, highlight: colours.highlight
}

export default {
  colours,
  textSizes,
  ui,
  defaultTheme
}
