/* eslint-disable react/prop-types */
import { h, Fragment } from 'preact'
import { useEffect } from 'preact/hooks'
import enGB from 'date-fns/locale/en-GB'

import { H1, Label } from '../../components/Text'
import Markdown from '../../components/Markdown'
import translations from '../../data/strings'
import InfoLayout from '../../layouts/InfoLayout'
import VideoEmbed from '../../components/VideoEmbed'
import {
  EpisodeCard,
  Title,
  InfoContent,
  Row,
  LogosRow,
  FooterImage,
  ActionButton as Button,
  TrailerContainer,
} from './styles'

import Page from '../../layouts/Page'
import { splitArray } from '../../helpers/utils'
import { defaultTheme } from '../../assets/theme'

const SeriesPage = ({ data }) => {
  const theme = data.theme || defaultTheme
  const { orgs } = data

  const credits = data.credits
    ? `
  ## Credits
  ${data.credits}
  `
    : null

  const orgsList = Object.values(orgs || {})

  const footerImages = data?.resources?.filter(({ title }) =>
    title.includes('FOOTER_IMG')
  )

  const links = data.links.length ? splitArray(data.links, 2) : null

  return (
    <Page title={data.title} theme={data.theme} withHeader={false}>
      <InfoLayout
        title={data.title}
        subtitle={data.subtitle}
        image={data.image}
        theme={theme}
      >
        <Fragment>
          <InfoContent>
            <H1>{data.title}:</H1>
            <H1>{data.subtitle}</H1>
            {data.description ? (
              <Markdown withLinebreaks theme={theme}>
                {data.description}
              </Markdown>
            ) : null}

            {data.trailer ? (
              <TrailerContainer>
                <VideoEmbed url={data.trailer} />
              </TrailerContainer>
            ) : null}

            {links
              ? links.map(linkRow => (
                  <Row>
                    {linkRow.map(link => (
                      <a
                        href={link.resourceUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button>{link.summary}</Button>
                      </a>
                    ))}
                  </Row>
                ))
              : null}
          </InfoContent>
          {data.episodes.future.length ? (
            <Fragment>
              <Title>{translations.en.program}:</Title>
              {data.episodes.future.map(feeditem => (
                <EpisodeCard theme={theme} key={feeditem.start} {...feeditem} />
              ))}
            </Fragment>
          ) : null}
          {data.episodes.past.length ? (
            <Fragment>
              <Title>Past streams:</Title>
              {data.episodes.past.map(feeditem => (
                <EpisodeCard
                  theme={theme}
                  key={feeditem.beginsOn}
                  hasPassed
                  onClickButton={() => null} // todo: fix this
                  {...feeditem}
                />
              ))}
            </Fragment>
          ) : null}
          {credits ? (
            <InfoContent>
              <Markdown theme={theme}>{credits}</Markdown>
            </InfoContent>
          ) : null}
        </Fragment>
        {orgsList.length ? (
          <LogosRow $wrap>
            {orgsList.map((org, index) => (
              <Fragment>
                <img src={org.logoUrl} alt={`${org.orgName} logo`} />
                {orgsList.length < 4 !== 1 &&
                orgsList.length < 4 &&
                index + 1 !== orgsList.length ? (
                  <Label colour={theme.foreground}>{'//'}</Label>
                ) : null}
              </Fragment>
            ))}
          </LogosRow>
        ) : null}
        {footerImages.length
          ? footerImages.map(image => (
              <FooterImage
                src={image.resourceUrl}
                alt={`${image.orgName} logo`}
              />
            ))
          : null}
      </InfoLayout>
    </Page>
  )
}

export default SeriesPage
