import { isBefore, format, isToday, isTomorrow } from 'date-fns'
import { capitaliseFirstLetter } from '../../helpers/string'
import strings from '../../data/strings'

export const getScheduleFromData = data => data.filter(item => {
  const { endsOn } = item
  const today = format(new Date(), 'yyyy/M/d')
  const broadcastEnd = new Date(endsOn)
  return !isBefore(broadcastEnd, new Date(today))
})
  .reduce((obj, item) => {
    const newObject = obj
    const { beginsOn } = item
    const startDay = format(new Date(beginsOn), 'yyyy-MM-dd')

    if (newObject[startDay]) {
      newObject[startDay] = [...newObject[startDay], item]
    } else {
      newObject[startDay] = [item]
    }

    return newObject
  }, {})



export const formatDay = (dateTime, lang = 'en') => {
  let day = ''
  const date = new Date(dateTime)
  if (isToday(date)) day = strings[lang].today
  if (isTomorrow(date)) day = strings[lang].tomorrow
  else day = format(date, 'cccc MMM d')
  return capitaliseFirstLetter(day)
}
