import { h } from 'preact'
import { useEffect, useRef, useState } from 'preact/hooks'
import { instanceOf, number, object, shape, string } from 'prop-types'

import { PeerTubePlayer } from '@peertube/embed-api'

import Chat from '../Chat'
import Overlay from '../VideoOverlay'
import { VideoWrapper, Iframe, PlayButton } from './styles'
import config from '../../data/config'
import { useToggle } from '../../hooks/utility'
import { useInterval } from '../../hooks/timerHooks'
import { useStreamStore, useUiStore } from '../../store'

const Video = () => {
  const { currentStream: stream, streamIsLive } = useStreamStore(store => store)
  const [isPlaying, setPlaying] = useState(false)
  const [isFullscreen, toggleIsFullscreen] = useToggle(false)
  // const [is1080p, toggle1080p] = useToggle(true)

  const videoiFrame = useRef(null)
  const overlayTimeout = useRef(null)
  const [videoReady, setVideoReady] = useState(false)
  const [overlayActive, setOverlayActiveState] = useState(true)
  const ptVideo = useRef(null)
  const resolutions = useRef(null)
  const [volume, setVolume] = useState(1)

  useEffect(() => {
    const setVideo = async () => {
      const player = new PeerTubePlayer(videoiFrame.current)
      await player.ready

      ptVideo.current = player
      player.setVolume(1)
      resolutions.current = await player.getResolutions()

      if (streamIsLive) {
        setPlaying(true)
        try {
          player.play()
        } catch (error) {
          console.log({ error })
          setOverlayActiveState(true)
          setPlaying(false)
        }
      }

      setVideoReady(true)
    }
    setVideo()
  }, [])

  const playVideo = () => {
    const { current: player } = ptVideo
    if (!videoReady) return

    setPlaying(true)
    try {
      player.play()
    } catch (error) {
      console.log({ error })
      setOverlayActiveState(true)
      setPlaying(false)
    }
  }
  const pauseVideo = () => {
    const { current: player } = ptVideo
    setPlaying(false)
    setOverlayActiveState(true)
    try {
      player.pause()
    } catch (error) {
      console.log({ error })
      setPlaying(true)
      setOverlayActiveState(false)
    }
  }

  const toggleVideo = () => {
    if (isPlaying) {
      pauseVideo()
    } else {
      playVideo()
    }
  }

  useEffect(() => {
    toggleVideo()
  }, [streamIsLive])

  useEffect(() => {
    if (!videoReady) return
    ptVideo.current.setVolume(volume)
  }, [volume])

  const toggleFullscreen = () => {
    toggleIsFullscreen()
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
    }
  }

  const volumeUp = () => {
    if (volume.current === 1) return
    setVolume(volume + 0.1)
  }

  const volumeDown = async () => {
    if (volume.current === 0) return
    console.log()

    setVolume(volume - 0.1)
    const vol = await ptVideo.current.getVolume()
    console.log({ vol })
  }

  const handleKeyPress = keyCode => {
    if (keyCode === 32) {
      // key == 'space'
      toggleVideo()
    }
    if (keyCode === 70) {
      // key == 'f'
      toggleFullscreen()
    }
    if (keyCode === 38) {
      // key == 'arrow UP'
      console.log('volup')
      volumeUp()
    }
    if (keyCode === 40) {
      // key == 'arrow DOWN'
      console.log('voldown')
      volumeDown()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', ({ keyCode }) => handleKeyPress(keyCode))

    return () =>
      window.removeEventListener('keydown', ({ keyCode }) =>
        handleKeyPress(keyCode)
      )
  }, [])

  const activateOverlay = () => {
    clearTimeout(overlayTimeout.current)
    setOverlayActiveState(true)

    overlayTimeout.current = setTimeout(
      () => setOverlayActiveState(false),
      2000
    )
  }

  return (
    <VideoWrapper
      $active={overlayActive || !isPlaying}
      onMouseMove={activateOverlay}
      isLive={streamIsLive}
    >
      <Overlay
        onClick={streamIsLive ? toggleVideo : null}
        active={overlayActive || !isPlaying}
        title={stream.title}
        onClickFullscreen={toggleFullscreen}
        isFullscreen={isFullscreen}
        resolutions={resolutions.current}
        videoRef={ptVideo.current}
        streamIsLive={streamIsLive}
      />
      {!isPlaying && <PlayButton streamIsLive={streamIsLive} />}
      <Iframe
        sandbox="allow-same-origin allow-scripts allow-popups"
        src={`${config.peertube_root}${stream.embedPath}?api=1&controls=false&vq=hd1080`}
        frameborder="0"
        allowfullscreen
        allow="autoplay"
        ref={videoiFrame}
      />
      <Chat overlayActive={overlayActive || !isPlaying} />
    </VideoWrapper>
  )
}

Video.propTypes = {
  // stream: shape({
  //   account: object,
  //   category: object,
  //   channel: object,
  //   description: string,
  //   duration: number,
  //   embedPath: string,
  //   end: instanceOf(Date),
  //   id: string,
  //   language: object,
  //   previewPath: string,
  //   start: instanceOf(Date),
  //   state: object,
  //   videoUrl: string,
  //   views: number,
  // }),
  // title: string.isRequired,
}

export default Video
