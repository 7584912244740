export default {
  en: [
    {
      label: 'Program guide',
      to: '/'
    },
    {
      label: 'Series',
      to: '/series'
    },
    {
      label: 'Archive',
      href: 'https://tv.undersco.re/search?sort=-match&searchTarget=local&search=livestream'
    }
  ]
}