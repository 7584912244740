import styled from 'styled-components'
import { screenSizes } from '../../assets/theme'
import { Row } from '../../components/Flex'
import { H1 } from '../../components/Text'

export const spacing = [2, 4, 8, 16, 24, 32, 48, 64, 128, 256, 512]

export const Content = styled.div`
  width: 85vw;
  max-width: ${screenSizes.lg + 150}px;
  margin: 0 auto;
  padding: 64px 0;
  overflow-y: scroll;
  min-height: 50vh;

  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;

`

export const SeriesGrid = styled.div`
  display: grid;
  grid-column-gap: ${spacing[5]}px;
  grid-row-gap: ${spacing[6]}px;
  margin-bottom: 5em;
  padding: 0 2px;
  @media screen and (min-width: ${screenSizes.sm}px) {
    grid-template-columns: repeat(1, minmax(380px, 1fr));
  }
  @media screen and (min-width: ${screenSizes.md}px) {
    grid-template-columns: repeat(2, minmax(380px, 1fr));
  }
  @media screen and (min-width: ${screenSizes.lg}px) {
    grid-template-columns: repeat(3, minmax(380px, 1fr));
  }
  @media screen and (min-width: ${screenSizes.xl}px) {
    grid-template-columns: repeat(4, minmax(380px, 1fr));
  }
`

export const Title = styled(H1)`
  margin-bottom: 0.5em
`