/* eslint-disable jsx-a11y/anchor-has-content */
import { h, Fragment } from 'preact'
import { decode } from 'he'
import MarkdownRenderer from 'markdown-to-jsx'
import { MarkdownWrapper } from './styles'
import { P, A, H1, H2, H3, Span } from '../Text'

const Markdown = ({ children, withLinebreaks, options, theme, ...rest }) => (
  <MarkdownWrapper $withLinebreaks={withLinebreaks} theme={theme}>
    <MarkdownRenderer
      options={{
        overrides: {
          p: {
            component: P,
          },
          span: {
            component: P,
          },
          em: {
            component: ({ children: spanChildren }) => (
              <Span fontStyle="italic">{spanChildren}</Span>
            ),
          },
          a: {
            component: props => (
              <A
                data-hoverable
                target="_blank"
                rel="noopener noreferrer"
                {...props}
              />
            ),
          },
          h1: {
            component: H1,
          },
          h2: {
            component: H2,
          },
          h3: {
            component: H3,
          },
        },
        ...options,
      }}
      {...rest}
    >
      {decode(children)}
    </MarkdownRenderer>
  </MarkdownWrapper>
)

export default Markdown
