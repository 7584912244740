import { h } from 'preact'
import { ICalendar } from 'datebook'
// import { format } from 'date-fns'
import striptags from 'striptags'
import { utcToZonedTime, zonedTimeToUtc, format } from 'date-fns-tz'
import enGB from 'date-fns/locale/en-GB'

import Link from '../Link'
import { H2, H3, Label } from '../Text'
import Flex from '../Flex'

import { Img, Left, Right, Center, Title, ButtonRow, StyledButton as Button } from './styles'

import strings from '../../data/strings'
import { colours, screenSizes } from '../../assets/theme'
import { andList } from '../../helpers/string'
import { useEventApi } from '../../hooks/data'
import { useWindowSize } from '../../hooks/dom'



const EpisodeCard = ({ image, title, seriesId, beginsOn, endsOn, id: episodeId, url, description, ...rest }) => {
  const { data: { series: allSeries } } = useEventApi()

  const series = seriesId ? allSeries.filter(({ id }) => id === seriesId)[0] : {}
  const hosts = series.hosts ? series.hosts.map(host => host.actor.name) : null
  const startDate = new Date(beginsOn)

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const utcDate = zonedTimeToUtc(startDate, timeZone)

  const zonedDate = utcToZonedTime(utcDate, timeZone)

  const startTime = format(zonedDate, 'h:mma', {
    timeZone,
    locale: enGB,
  })
  const tzShort = format(zonedDate, 'zzz', {
    timeZone,
    locale: enGB,
  })

  const { width: screenWidth } = useWindowSize()
  const isMobile = screenWidth < screenSizes.md


  return (
    <Flex align="stretch" direction={isMobile ? 'column' : 'row'} {...rest}>
      <Left>
        <Link to={`/series/${series.slug}#${episodeId}`}>
          <Img src={image} />
        </Link>
        <ButtonsRows title={title} description={description} beginsOn={beginsOn} endsOn={endsOn} url={url} />
      </Left>
      <Center justify="space-betweeen">
        <Title size={24} colour={colours.rose} weight="500">{title}</Title>
        <div>
          <H3 weight="500" colour={colours.rose}>From: <Link textProps={{
            weight: '700'
          }} to={`/series/${series.slug}`}>{`${series.title}: ${series.subtitle}`}</Link></H3>
          {hosts ? <Label size={16} colour={colours.rose}>— {andList(hosts)}</Label> : null}
        </div>
      </Center>
      <Right>
        <Label size={24} colour={colours.rose} weight="600" align="right" block>{startTime}</Label>
        <Label size={18} colour={colours.rose} weight="600" align="right" block>{tzShort}</Label>
      </Right>
    </Flex>
  )
}

export const ButtonsRows = ({ title, description, beginsOn, endsOn, url }) => {
  const icalendar = new ICalendar({
    title,
    location: 'https://stream.undersco.re/',
    description: description ? striptags(description) : '',
    start: new Date(beginsOn),
    end: new Date(endsOn),
  })

  const dlIcal = () => icalendar.download()
  return (
    <ButtonRow justify="space-between">
      <Button onClick={dlIcal}>{strings.en.subEvent}</Button>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <Button>{strings.en.eventDetails}</Button>
      </a>
    </ButtonRow>)
}


export default EpisodeCard
