import { h } from 'preact'
import styled from 'styled-components'
import { colours } from '../../assets/theme'
import { P } from '../Text'

export const MarkdownWrapper = styled.span`
  a {
    color: ${({ theme }) => theme && theme.highlight || colours.highlight};
  }

  img {
    max-width: 300px;
    float: left;
    padding: 0 12px 6px 0;
  }

  h1 {
    margin-bottom: 1em;
  }

  h2 {
    margin-bottom: 0.5em;
  }

  h3 {
    margin: 1em 0 0.5em 0;
  }

  p {
    margin-bottom: ${props => (props.$withLinebreaks ? '32px' : '0')};

    &:last-of-type {
      margin-bottom: ${props => (props.$withLinebreaks ? '8px' : '0')};
    }
  }

  p > p {
    display: inline;
    margin-bottom: 0;
  }

  li {
    margin-left: 1em;
  }
  ul {
    margin-bottom: 1em;
  }
`
