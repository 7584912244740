export const slugify = (title) => {
  let str = title ? title.replace(/^\s+|\s+$/g, '') : null // trim

  if (!str) return title

  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
  const to = 'aaaaeeeeiiiioooouuuunc------'
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes

  return str

}

export const capitaliseFirstLetter = word =>
  word ? `${word.charAt(0).toUpperCase()}${word.slice(1)}` : word

export const camelise = str => str ? str
  .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => index === 0 ? letter.toLowerCase() : letter.toUpperCase())
  .replace(/\s+/g, '') : str

/**
 *
 * Takes an array of strings and returns a string list separated by
 * commas and an 'and' connector before the last item.
 * @param {Array} list - List of strings || 2d array of strings. [0] = string to display, [1] = string to inject into before/after.
 * @param {string} [connector] - Optional connector to join the last item, defaults to '&'.
 * @param {Object} [wrapEachItem] - Optional object to wrap each item with a string.
 * @param {string} [wrapEachItem.before] - Optional string to prefix each item.
 * @param {string} [wrapEachItem.after] - Optional string to suffix each item.
 * @param {string} [replace] - String to be replaced by second part of list item array if supplied.
 *
 */
export const andList = (list, connector = '&', wrapEachItem, replace = '{#}') => {
  if (!Array.isArray(list)) return list

  const wrapItem = (item) => {
    if (wrapEachItem) {
      if (Array.isArray(item)) {
        return `${wrapEachItem.before.replace(replace, item[1])}${item[0]}${wrapEachItem.after.replace(replace, item[1])}`
      }
      return `${wrapEachItem.before}${item}${wrapEachItem.after}`
    }
    return item
  }

  return list.map((item, index) => {
    if (index + 1 === list.length && index !== 0) {
      return ` ${connector} ${wrapItem(item)}`
    }

    return list.length === 1 || index === list.length - 2 ? wrapItem(item) : `${wrapItem(item)}, `
  }).join('')
}