import { h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
// eslint-disable-next-line import/no-extraneous-dependencies

import { secondsToMilliseconds } from 'date-fns'
import axios from 'axios'
// import ICAL from 'ical.js'
import config from '../data/config'
import { useSeriesStore, useStreamStore } from '../store/index'
import { useInterval } from './timerHooks'

// export const useEventCalendar = () => {
//   const [data, setData] = useState([])
//   const [loading, setLoading] = useState(true)

//   async function fetchData() {
//     setLoading(true)

//     const { data: responseData } = await axios.get(
//       `https://cloud.undersco.re/remote.php/dav/public-calendars/${config.calendarId}/?export`
//     )
//     const jCalData = ICAL.parse(responseData)
//     const comp = new ICAL.Component(jCalData)

//     const vevents = comp.getAllSubcomponents('vevent')

//     const calEvents = vevents
//       .filter(
//         vevent =>
//           vevent.getFirstPropertyValue('status') === null ||
//           (vevent.getFirstPropertyValue('status') &&
//             vevent.getFirstPropertyValue('status').toUpperCase() ===
//               'CONFIRMED')
//       )
//       .map(vevent => {
//         const event = new ICAL.Event(vevent)
//         return event
//       })
//       .sort((a, b) => a.startDate.toJSDate() - b.startDate.toJSDate())

//     await Promise.all(
//       calEvents.map(async calItem => {
//         const url = calItem.component.getAllProperties('url')[0]
//         if (url) {
//           const id = url
//             .getFirstValue()
//             .split('/')
//             .pop()
//           const {
//             data: {
//               account,
//               category,
//               channel,
//               embedPath,
//               language,
//               state,
//               previewPath,
//               views,
//               duration,
//             },
//           } = await axios.get(`https://tv.undersco.re/api/v1/videos/${id}`)

//           const item = {
//             title: calItem.summary,
//             account,
//             category,
//             channel,
//             description: calItem.description,
//             embedPath,
//             language,
//             state,
//             previewPath,
//             views,
//             start: calItem.startDate.toJSDate(),
//             end: calItem.endDate.toJSDate(),
//             id,
//             duration,
//             videoUrl: url.getFirstValue(),
//           }
//           setData(arr => [...arr, item])
//         } else {
//           const item = {
//             title: calItem.summary,
//             description: calItem.description,
//             start: calItem.startDate.toJSDate(),
//             end: calItem.endDate.toJSDate(),
//           }
//           setData(arr => [...arr, item])
//         }
//       })
//     )

//     setLoading(false)
//   }

//   useEffect(() => {
//     fetchData()
//   }, [])

//   return { loading, data }
// }

export const useEventApi = () => {
  const [data, setData] = useSeriesStore(store => [
    store.series,
    store.setSeries,
  ])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(!!data.length)

  async function fetchData() {
    if (!data.length) {
      setLoading(true)

      try {
        const { data: responseData } = await axios.get(
          `${config.EVENTS_API_URL}/events`
        )
        setData(responseData)
        setLoading(false)
      } catch (err) {
        console.log('ERROR')
        setError(err)
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return { loading, data, error }
}

export const usePeertubeApi = async () => {
  const {
    currentStream,
    setCurrentStream,
    setStreamIsLive,
    streamIsLive,
  } = useStreamStore(store => store)

  if (!currentStream) return

  const fetchData = async () => {
    const { peertubeLive } = currentStream
    if (!peertubeLive || !peertubeLive.id) return

    const {
      data: { state, embedPath },
    } = await axios.get(
      `https://tv.undersco.re/api/v1/videos/${peertubeLive.id}`
    )

    setStreamIsLive(state.id === 1)
    setCurrentStream({ ...currentStream, embedPath })
  }

  useEffect(() => {
    fetchData()
  }, [])

  useInterval(
    () => {
      fetchData()
    },
    streamIsLive ? secondsToMilliseconds(15) : secondsToMilliseconds(1)
  )
}
