import { h } from 'preact'
import styled, { css } from 'styled-components'
import { colours } from '../../assets/theme'
import ChevronSvg from '../Svg/Chevron'
import { Label } from '../Text'

export const Container = styled.div`
  position: relative;
  max-width: min-content;
  margin-left: 8px;
  background-color: red;
`

export const OptionsWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: ${props => props.bottom ? 'column-reverse' : 'column'};
  bottom: ${props => props.bottom ? '100%' : 'auto'};
  transform: translateY(${props => props.bottom ? '1' : '-1'}px);
  

  div {
    margin-${props => props.bottom ? 'top' : 'bottom'}: -1px;
  }
`

export const Box = styled.div`
  padding: 4px 16px;
  /* border: 1px solid ${({ theme }) => theme.foreground}; */
  /* background: ${({ theme }) => theme.background}; */
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${colours.midnight};
  min-width: 45px;
  text-align: center;

  label {
    position: relative;
    top: 1px;
    line-height: 1;
  }

  ${({ selectable }) => selectable && css`
    &:hover {
      background: ${colours.white};
      label {
        color: ${colours.midnightDarker};
      }
    }
  `}
`

export const Item = ({ label, children, ...rest }) => (
  <Box selectable {...rest}>
    <Label>{label || children}</Label>
  </Box>
)

export const ChevronIcon = styled(ChevronSvg)`
  margin-left: 8px
`
