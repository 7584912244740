import { h } from 'preact'
import { oneOf, bool, string, number, oneOfType, node } from 'prop-types'

import { colours } from '../../assets/theme'

import { TextBase } from './styles'

const Text = ({
  tag = 'span',
  weight = '500',
  colour: colourProp,
  lineHeight = '16px',
  fontFamily = 'Karla',
  children,
  size,
  sizeUnit,
  selectable = true,
  fontStyle,
  ...rest
}) => {
  const colour = colourProp || colours.text

  return (
    <TextBase
      colour={colour}
      as={tag}
      weight={weight}
      lineHeight={lineHeight}
      $fontFamily={fontFamily}
      $size={size}
      $sizeUnit={sizeUnit}
      $fontStyle={fontStyle}
      selectable={selectable}
      {...rest}
    >
      {children}
    </TextBase>
  )
}

Text.propTypes = {
  tag: oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'span',
    'a',
    'li',
    'div',
    'small',
    'q',
    'label',
  ]),
  weight: oneOf(['500', '700', 'inherit']),
  colour: string,
  align: string,
  size: oneOfType([string, number]),
  lineHeight: number,
  fontFamily: oneOfType([string, oneOf([0, 1])]),
  children: node,
  selectable: bool,
}

export const H1 = ({ children, size, ...rest }) => (
  <Text
    tag="h1"
    weight="700"
    $size={size || '48'}
    lineHeight="0.8"
    fontFamily="Lunchtype24"
    {...rest}
  >
    {children}
  </Text>
)

export const H2 = ({ children, size, ...rest }) => (
  <Text
    tag="h2"
    weight="700"
    $size={size || '25'}
    lineHeight="1"
    fontFamily="Lunchtype24"
    {...rest}
  >
    {children}
  </Text>
)

export const H3 = ({ children, size, weight = '700', ...rest }) => (
  <Text
    tag="h3"
    weight={weight}
    $size={size || '21'}
    lineHeight="1"
    fontFamily="Lunchtype24"
    {...rest}
  >
    {children}
  </Text>
)

export const P = ({ children, size, ...rest }) => (
  <Text
    tag="p"
    weight="400"
    $size={size || '15'}
    lineHeight="21px"
    fontFamily="Karla"
    {...rest}
  >
    {children}
  </Text>
)
export const Span = ({ children, colour, size, weight, ...rest }) => (
  <Text tag="span" colour={colour || 'inherit'} $size={size || 'inherit'} weight={weight || 'inherit'} {...rest}>
    {children}
  </Text>
)
export const Label = ({ children, size, ...rest }) => (
  <Text
    tag="label"
    weight="500"
    $size={size || '15'}
    lineHeight="13px"
    fontFamily="Karla"
    {...rest}
  >
    {children}
  </Text>
)
export const A = ({ children, colour, fontFamily, ...rest }) => (
  <Text tag="a" colour={colour || colours.highlight} fontFamily={fontFamily} {...rest}>
    {children}
  </Text>
)
export const Li = ({ children, ...rest }) => (
  <Text tag="li" {...rest}>
    {children}
  </Text>
)

export default Text
