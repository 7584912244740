import { h } from 'preact'
import { Svg } from './base'

import { svgPropTypes } from './proptypes'

const Cross = ({ colour = 'inherit', size, ...rest }) => (
  <Svg viewBox="0 0 100 100" height={size} {...rest}>
    <path
      stroke={colour}
      strokeLinecap="none"
      strokeLinejoin="none"
      strokeWidth="18"
      d="M11.354 11.757l77.637 77.636m-77.627 0L89 11.757"
    />
  </Svg>
)

Cross.propTypes = {
  ...svgPropTypes,
}

export default Cross
