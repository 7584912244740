import { h } from 'preact'

import { useRouteMatch } from 'react-router-dom'

import { A } from '../Text'
import { RRLink } from './styles'

const Link = ({ children, to, activeOnlyWhenExact = true, href, textProps: { colour, size, weight } = {}, navLink, ...rest }) => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact
  })

  return href ? <A href={href} colour={colour} size={size} fontFamily={navLink ? 'Lunchtype24' : null} {...rest}>{children}</A> : (
    <A as="span" colour={colour} size={size} weight={weight} {...rest}>
      <RRLink to={to} $navLink={navLink} $colour={colour} $selected={match && navLink}>{children}</RRLink>
    </A>
  )
}

export default Link