import { h } from 'preact'

import translations from '../../data/strings'
import { H1, H2 } from '../../components/Text'
import {
  Wrapper,
  LoaderWrapper,
  Hero,
  PositionedLogo as Logo,
  TaglineContainer,
  ErrorBlock
} from './styles'
import Loader from '../../components/Loader'
import { colours } from '../../assets/theme'

const LoaderLayout = ({ error }) => (
  <Wrapper>
    <Logo active />
    <LoaderWrapper>
      {error ? (
        <ErrorBlock>
          <H1 colour={colours.white}>{translations.en.errorTitle}</H1>
          <H2 colour={colours.white}>{translations.en.errorBody}</H2>
        </ErrorBlock>) : <Loader />}
    </LoaderWrapper>
    <Hero />
    <TaglineContainer>
      {translations &&
        translations.en.underscoreTagline.map(line => (
          <H1 key={line} colour={colours.midnightDarker}>{line}</H1>
        ))}
    </TaglineContainer>
  </Wrapper>
)

export default LoaderLayout
