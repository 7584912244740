/* eslint-disable react/prop-types */
import { addYears, isFuture } from 'date-fns'
import { Fragment, h } from 'preact'
import { H1, H2 } from '../../components/Text'
import strings from '../../data/strings'
import { useEventApi } from '../../hooks/data'
import { Content, SeriesGrid, SeriesRow, Title } from './styles'

import Page from '../../layouts/Page'
import SeriesCard from '../../components/SeriesCard'
import { colours } from '../../assets/theme'

const Series = () => {
  const { data } = useEventApi()
  const pastSeries = []

  const currentSeries = data.series
    ? data.series.filter(series => {
        if (series.episodes.future.length) {
          return true
        }
        if (
          series.episodes.past.every(({ beginsOn }) =>
            isFuture(addYears(new Date(beginsOn), 1))
          )
        ) {
          return true
        }

        pastSeries.push(series)
        return false
      })
    : []

  return (
    <Page title={strings.en.series}>
      <Content>
        <Fragment>
          <Title colour={colours.rose}>{strings.en.currentSeries}</Title>
          <SeriesGrid>
            {currentSeries.reverse().map(series => (
              <SeriesCard series={series} />
            ))}
          </SeriesGrid>
        </Fragment>
        {pastSeries.length ? (
          <Fragment>
            <Title colour={colours.rose}>{strings.en.pastSeries}</Title>
            <SeriesGrid>
              {pastSeries.map(series => (
                <SeriesCard series={series} isPast />
              ))}
            </SeriesGrid>
          </Fragment>
        ) : null}
      </Content>
    </Page>
  )
}

export default Series
