import { h } from 'preact'
import { Svg } from './base'
import { svgPropTypes } from './proptypes'

const Chevron = ({ colour = 'inherit', size, ...rest }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={size}
    viewBox="0 0 100 100"
    {...rest}
  >
    <path
      fill={colour}
      fillRule="evenodd"
      d="M49.68 19.005l.002-.003 46.733 46.733-14.849 14.85-31.889-31.89-30.889 30.89L3.94 64.735 49.675 19l.005.005z"
      clipRule="evenodd"
    />
  </Svg>
)

Chevron.propTypes = {
  ...svgPropTypes,
}

export default Chevron
