import { h } from 'preact'
import styled from 'styled-components'
import { Label } from '../Text'
import translations from '../../data/strings'
import Button from '../Button'
import Loader from '../Loader'
import { colours } from '../../assets/theme'

const getCursor = ({ isLive, $active }) => {
  if (!isLive) return 'default'
  return $active ? 'pointer' : 'none'
}

export const VideoWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: ${props => getCursor(props)};
  display: flex;
  justify-content: center;
  align-items: center;
`



// const g = Label?.f?.e

export const Iframe = styled.iframe`
  z-index: -1;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: absolute;
`
export const Overlay = styled.div`
  z-index: 1;
  position: fixed;
  height: 100vh;
  width: 100vw;
`

const ButtonWrapper = styled.div`
  div {
    padding: 1em 2em;
    background-color: #ffffffba;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid ${colours.midnight};
  }

  label {
    color: ${colours.midnightDarker};
    margin: ${props => props.streamIsLive ? '0' : '8px 0 0 8px'};
    font-size: 16px;
  }

  :hover div {
    background-color: #ffffff;
  }
`

export const PlayButton = props => (
  <ButtonWrapper {...props}>
    <div>
      {!props.streamIsLive && <Loader colour={colours.midnight} rate={500} />}
      <Label>{props.streamIsLive ? translations.en.joinStream : translations.en.streamStartingSoon}</Label>
    </div>
  </ButtonWrapper>
)

export const ActionButton = styled(Button)`
  font-size: 18px;
`
