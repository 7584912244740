import styled from 'styled-components'
import { colours } from '../../assets/theme'

const Button = styled.button`
  background-color: transparent;
  border: 1px solid ${props => props.colour || colours.rose};
  padding: 0.3em 1em;
  font-family: Karla;
  font-weight: inherit;
  color: ${props => props.colour || colours.rose};
  opacity: 1;
  text-decoration: none;
  font-size: 24px;
  cursor: pointer;
  width: 100%;

  svg {
    margin-right: 12px;
    position: relative;
    top: 2px;
  }

  :hover {
    background-color: ${props => props.hoverColour || colours.rose};
    color: ${colours.midnightDarker};

    svg {
      path {
        stroke: ${colours.roseLight};
      }
    }
  }
`

export default Button
