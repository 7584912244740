import styled from 'styled-components'
import { screenSizes } from '../../assets/theme'
import { Row } from '../Flex'
import CrossSvg from '../Svg/Cross'


export const StyledRow = styled(Row)`
  pointer-events: all;
  height: 100px;
  background-color: transparent;
  color: ${({ theme }) => theme.foreground};
  position: absolute;
  left: ${({ miniHeader }) => miniHeader ? 'initial' : '0'};
  top: 0;
  right: 0;
  z-index: 1;

  img {
    max-height: 80px;
    mix-blend-mode: exclusion;
    padding: 1em;
  }

  
  a, span {
    margin-right: 2em;
    cursor: pointer;
    
    @media screen and (max-width: ${screenSizes.md}px) {
      margin-right: 1em;
    }

    &:hover {
      opacity: 0.5;
    }
  }
`
export const RightBox = styled(Row)`
`

export const Modal = styled.div`
  z-index: 100;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.foreground};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: all;

  
  img {
    max-height: 80px;
    mix-blend-mode: exclusion;
    padding: 1em;
  }
  
  div {
    padding: 3em;
    display: flex;
    flex-direction: column;
  }

  div a {
    margin-bottom: 1em;
    font-size: 6vh;
    display: inline-block;
    line-height: 0.8;
  }
`
export const PositionedCross = styled(CrossSvg)`
  position: fixed;
  right: 2.5em;
  top: 2em;
  cursor: pointer;
`