import styled from 'styled-components'
import { colours } from '../../assets/theme'
import { Label } from '../Text'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 3em;
  width: min-content;

  h2 {
    margin-bottom: 0.1em;
  }
  h3 {
    margin-bottom: 0.5em;
  }

  &:hover div {
    filter: invert()
  }
`

export const Img = styled.div`
  background: url(${({ src }) => src});
  width: 380px;
  height: 215px;
  background-size: cover;
  position: relative;
  background-position: center;
  margin: 0 0 1em 0;
`

export const LabelBlock = styled(Label).attrs(props => ({
  colour: props.colour || colours.midnightDarker
}))`
  position: absolute;
  background-color: ${colours.white};
  right: 0;
  ${({ $position }) => $position === 'top' ?
    'top: 0;' : 'bottom: 0;'
  };
  padding: 4px
`