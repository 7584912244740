import { h } from 'preact'
import { bool, string } from 'prop-types'
import Helmet from 'react-helmet'
import config from '../../data/config'

const siteTitle = config.meta.title

function SEO({ description = config.meta.description, title, metaImg: imgSrc = config.meta.img, noindex }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={title}
      titleTemplate={`%s \\ ${siteTitle}`}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title ? `${title} \\\\ ${siteTitle}` : siteTitle,
        },
        {
          property: 'og:url',
          content: 'https://stream.undersco.re/',
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:image',
          content: imgSrc,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:image',
          content: `${imgSrc}`,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'apple-mobile-web-app-capable',
          content: 'yes',
        },
        (noindex ? {
          name: 'robots',
          content: 'noindex',
        } : {}),
      ].concat()}
    />
  )
}

SEO.defaultProps = {
  meta: [],
  description: '',
}

SEO.propTypes = {
  description: string,
  metaImg: string,
  title: string.isRequired,
  noindex: bool,
}

export default SEO
