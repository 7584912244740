import create from 'zustand'
import { defaultTheme } from '../assets/theme'

export const useSeriesStore = create((set, get) => ({
  series: {},
  episodes: [],

  // Methods
  setSeries: series => set({ series }),
  setEpisodes: () => {
    if (get().series) {
      set({
        episodes: get().series.map(series => series.episodes)
      })
    } else set({})
  }
}))

export const useTheme = create(set => ({
  theme: defaultTheme,

  // Methods
  setTheme: (theme) => set({ theme }),
  setDefaultTheme: () => set({ theme: defaultTheme })
}))

export const useUiStore = create((set, get) => ({
  mobileMenuOpen: false,
  streamPreviewMinimized: false,
  streamActive: false,

  // Methods
  toggleMobileMenu: () => set({ mobileMenuOpen: !get().mobileMenuOpen }),
  toggleStreamPreviewMinimized: () => set({ streamPreviewMinimized: !get().streamPreviewMinimized }),
  setStreamActive: (streamActive) => set({ streamActive }),
}))

export const useStreamStore = create((set) => ({
  currentStream: null,
  streamIsLive: false,

  // Methods
  setCurrentStream: (currentStream) => set({ currentStream }),
  setStreamIsLive: (streamIsLive) => set({ streamIsLive }),
}))